













































import { Component, Prop, Vue } from "vue-property-decorator";
import { Card } from "@/types/cms/components";

/**
 * CMS card component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
  components: {
    "cms-component-image": () =>
      import("@/components/cms/components/CMSComponentImage.vue"),
  },
})
export default class CMSComponentCard extends Vue {
  // Prop with card data
  @Prop({ type: Object as () => Card, required: true }) readonly cardData:
    | Card
    | undefined;

  /**
   * @returns route url
   *
   * @author Kevin Danne <danne@skiba-procomputer.de>
   */
  private get routeUrl(): string {
    if (!this.cardData) return "";

    const url = this.cardData.button.routeUrl;

    // returns absolute url when mailto link
    if (url.startsWith("mailto:")) {
      return url;
    }

    // returns relative url
    return "#" + url;
  }

  /*
   * @param relativeUrl relative image url
   *
   * @returns API url + relative image url
   *
   * @author Kevin Danne <danne@skiba-procomputer.de>
   */
  private getFullImageUrl(relativeUrl: string): string {
    return process.env.VUE_APP_API_URL + relativeUrl;
  }
}
